import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserAuth, getPartner_Id } from '../../../utils/LocalStorageUtils';
import Footer from '../Footer';
import NavBar from '../NavBar';

const AuthLayout = ({ title, children, partnerId }) => {
  const history = useHistory();
  const [logoError, setLogoError] = useState(null);
  const auth = getUserAuth();
  const [logo, setLogo] = useState(null);

  // Fetch partnerId from localStorage if not passed as prop
  const effectivePartnerId = partnerId || getPartner_Id(); 

  const fetchLogo = (partnerId) => {
    return `${process.env.REACT_APP_BASE_URL}/logo/${partnerId}`; 
  };

  useEffect(() => {
    if (effectivePartnerId) {
      const logoUrl = fetchLogo(effectivePartnerId);
      setLogo(logoUrl); 
      setLogoError(null); 
    } else {
      setLogoError('Partner ID not found'); 
    }
  }, [effectivePartnerId]);

  return (
    <div className='row mx-0 h-100vh auth-wrapper'>
      <div className="col-md">
        <img
          src={logo || '/default-logo.png'} 
          className="p-4"
          width="180"
          alt="Logo"
          onClick={() => history.push("/user/home")} 
        />
      </div>
      <div className="col-md-5 col-12 px-0 border-start border-end d-flex flex-column" style={{ minHeight: '100vh' }}>
        <div className="d-flex justify-content-center flex-column h-100 pt-5">
          <h2 className="display-6 mb-4 px-5 title">{title}</h2>
          <div className="px-5">
            {children}
          </div>
        </div>
        <div className="p-5">
          <Footer />
        </div>
      </div>
      <div className="col-md">
        {auth && <NavBar showOnlyMenu />}
      </div>
    </div>
  );
};

export default AuthLayout;
