import { setUserTransactionToken } from "../../utils/LocalStorageUtils";
import { STATUS_SUCCESS } from "../../utils/constants/constants";
import { httpFileUpload, httpGet, httpGetBlob, httpPost, httpPut } from "./AxiosHelper";
import './AxiosInterceptor';

export const apiLogin = (data) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: "/login", body: data })
      .then((data) => {
        resolve(data);
        if (data.status) {
          localStorage.setItem("transactionToken", data.data.transactionToken);
        }
        // if (data.status) {
        //   console.log("data in api recived:", data);
        //   console.log(data.data.transactionToken);
        //   localStorage.setItem("transactionToken", data.data.transactionToken);
        //   resolve(data);
        // } else {
        //   reject(data.description);
        // }
      })
      .catch((err) => { console.log(err); reject(err) });
  })
}

export const apiRegister = (data) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: "/investor-sign-up", body: data })
      .then((res) => {
        if (res.status) {
          resolve(res.data);
        } else {
          reject(res.description);
        }
      })
      .catch((err) => { console.log(err); reject(err); });
  });
};

export const apiGetOTP = (data) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: "/get-otp", body: data })
      .then((res) => {
        if (res.status) {
          setUserTransactionToken(res.data.transactionToken)
          resolve(res.data);
        } else {
          reject(res.description);
        }
      })
      .catch((err) => reject(err));
  })
}

export const verifyOtp = (data) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: "/verify-otp", body: data })
      .then((res) => {
        if (res.status) {
          resolve(res.data);
        } else {
          reject(res.description);
        }
      })
      .catch((err) => console.log(err));
  });
};

export const apiVerifyEmail = (data) => {
  return new Promise((resolve, reject) => {
    httpPost({ path: "/verify-email", body: data })
      .then((res) => {
        if (res.status) {
          resolve(res.data);
        } else {
          reject(res.description);
        }
      })
      .catch((err) => console.log(err));
  });
};

export const verifyPan = (data) => {
  return new Promise((resolve, reject) => {
    // const jwt = localStorage.getItem("JWT");
    // console.log("from local", jwt);
    const JWT = localStorage.getItem("JWT");
    httpPost({ path: "/webapp/verify-pan", body: data, JWT: JWT })
      .then((data) => {
        if (data.status) {
          resolve(data);
        } else {
          reject(data.description);
        }
      })
      .catch((err) => console.log(err));
  });
};

export const getEsignURL = (reqParams) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    httpGet({ path: "/webapp/mca/sign", JWT: JWT, queryParams: reqParams, })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const updateEsignStatus = (data) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    httpPost({ path: "/update-esign-status", body: data, JWT: JWT })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const savePanDetails = (data) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    httpPost({ path: "/save-pan", body: data, JWT: JWT })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const getKyc = () => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    httpGet({ path: "/kyc", JWT: JWT })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const postKyc = () => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    httpPost({ path: "/kyc", JWT: JWT })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const getUserDetails = () => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    httpGet({ path: "/webapp/get-user-details", JWT: JWT })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const apiUpdateInvestor = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({path: '/webapp/save-investor', body: body })
    .then((res) => {
      if (res.status === STATUS_SUCCESS) {
        return resolve(res.description)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const apiUpdateInvestorDematInfo = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({path: '/webapp/save-demat-info', body: body })
    .then((res) => {
      if (res.status === STATUS_SUCCESS) {
        return resolve(res.description)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const saveInvestmentPreference = (data) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    httpPost({
      path: "/webapp/save-investment-preference",
      JWT: JWT,
      body: data,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const getAvailableProjects = (reqParams) => {
  return new Promise((resolve, reject) => {
    const params = reqParams;
    httpGet({
      path: "/webapp/opportunity",
      queryParams: params,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const getPartners = (reqParams) => {
  return new Promise((resolve,reject) => {
    httpGet({path: '/webapp/partner-list', param: reqParams})
    .then((res) => {
      if (res.status === STATUS_SUCCESS && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const getMasterClass = (params) => {
  return new Promise((resolve,reject) => {
    httpGet({path: '/webapp/class-master',  param: params})
    .then((res) => {
      if (res.status === STATUS_SUCCESS && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const getOpportunityDetails = (reqParams) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    const params = reqParams;

    httpGet({
      path: "/webapp/get-opportunity-details",
      JWT: JWT,
      queryParams: params,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const createEnach = (reqBody) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");

    httpPost({
      path: "/webapp/create-enach",
      JWT: JWT,
      body: reqBody,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const apiTransactioWalletTopup = (reqBody) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: "/webapp/transaction/wallet/top-up",
      body: reqBody,
    })
      .then((res) => {
        res.status ? resolve(res.data) : reject(res.description);
      })
      .catch((err) => reject(err));
  });
}

export const apiTransactionDetails = (orderId) => {
  return new Promise((resolve, reject) => {

    httpGet({
      path: "/webapp/transaction/" + orderId,
      queryParams: null,
    })
      .then((res) => {
        res.status ? resolve(res.data) : reject(res.description);
      })
      .catch((err) => reject(err));
  });
}

export const apiTransactions = () => {
  return new Promise((resolve, reject) => {

    httpGet({
      path: "/webapp/transactions",
      queryParams: null,
    })
      .then((res) => {
        res.status ? resolve(res.data) : reject(res.description);
      })
      .catch((err) => reject(err));
  });
}

export const apiSaveTransactionView = (itRid) => {
  return new Promise((resolve, reject) => {

    httpPost({
      path: "/webapp/transaction/" + itRid + "/viewed",
      body: null,
    })
      .then((res) => {
        res.status ? resolve(res.data) : reject(res.description);
      })
      .catch((err) => reject(err));
  });
}

export const getDrawDown = (reqParams) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    const params = reqParams;

    httpGet({
      path: "/webapp/drawdown",
      JWT: JWT,
      queryParams: params,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const sipPreference = (reqBody) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");

    httpPost({
      path: "/webapp/sip-preference",
      JWT: JWT,
      body: reqBody,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const getDashboard = () => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");

    httpGet({
      path: "/webapp/dashboard",
      JWT: JWT,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const getWallet = () => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");

    httpGet({
      path: "/webapp/wallet",
      JWT: JWT,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const getUserEnachDetails = () => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");

    httpGet({
      path: "/webapp/get-user-enach-details",
      JWT: JWT,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const getDrawDownDetails = (reqParams) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    const params = reqParams;

    httpGet({
      path: "/webapp/mca/status",
      JWT: JWT,
      queryParams: params,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};



export const apiTokenAuth = (reqBody) => {
  return new Promise((resolve, reject) => {

    httpPost({
      path: "/token-auth",
      body: reqBody,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const getInvestorOpportunity = (reqParams) => {
  const JWT = localStorage.getItem("JWT");
  const params = reqParams
  return new Promise((resolve, reject) => {

    httpGet({
      path: "/webapp/investor-opportunity",
      JWT: JWT,
      queryParams: params
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const updateConsent = (reqBody) => {
  const JWT = localStorage.getItem("JWT");
  const body = reqBody
  return new Promise((resolve, reject) => {

    httpPost({
      path: "/webapp/consent",
      JWT: JWT,
      body: body
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const getFileAsBlob = (url) => {
  return new Promise((resolve, reject) => {

    httpGetBlob({
      path: url,
    })
      .then((data) => {
        const file = new Blob([data.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        resolve(fileURL)
      })
      .catch((err) => reject(err));
  });
}

export const getDataDictionaryValues = async () => {
  const JWT = localStorage.getItem("JWT");

  return new Promise((resolve, reject) => {

    httpGet({
      path: "/webapp/list-data-dictionaries",
      JWT: JWT,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

export const apiCompanyUpdates = (reqParams) => {
  const JWT = localStorage.getItem("JWT");
  const params = reqParams
  return new Promise((resolve, reject) => {
    httpGet({
      path: "/webapp/company-update",
      JWT: JWT,
      queryParams: params
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const apiCompanyUpdateDetials = (reqParams) => {
  const JWT = localStorage.getItem("JWT");
  const params = reqParams
  return new Promise((resolve, reject) => {

    httpGet({
      path: "/webapp/company-update/" + params.cuRid,
      JWT: JWT
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const apiTags = (reqParams) => {
  const JWT = localStorage.getItem("JWT");
  const params = reqParams
  return new Promise((resolve, reject) => {

    httpGet({
      path: "/admin/tags",
      JWT: JWT,
      queryParams: params
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const apiInvestments = (reqParams) => {
  const JWT = localStorage.getItem("JWT");
  const params = reqParams
  return new Promise((resolve, reject) => {

    httpGet({
      path: "/webapp/investments",
      JWT: JWT,
      queryParams: params
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}


export const getInvestmentDetail = (ioRid) => {
  return new Promise((resolve, reject) => {

    httpGet({
      path: `/webapp/get-investment-details`,
      queryParams: { ioRid }
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

export const silentLogin = (temptoken) => {
  return new Promise((resolve, reject) => {
    httpGet({
      path: `/silent-login`,
      queryParams: { "x-auth-token": temptoken }
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });

}

export const showInterest = (temptoken) => {
  return new Promise((resolve, reject) => {
    httpGet({
      path: `/webapp/from-scheme-launch/show-interest`,
      queryParams: { "token": temptoken }
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });

}

export const saveInvestment = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/commit`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

export const apiSaveDraftEmailInvestment = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/save-draft-email-investment`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

export const apiDraftDrawdownFile = (reqParams) => {
  return new Promise((resolve, reject) => {
    httpGet({
      path: `/webapp/get-draft-drawdown/${reqParams?.diRid}`,
      queryParams: reqParams
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

export const apiSaveConsent = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/consent`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

//api for non regitstered user commitment -- schemeEmailer
export const apiSaveNonRegisteredCommitment = ( body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/from-scheme-launch/commit/not-registered`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

//api for registered user commitment-- scheme Emailr

export const apiSaveRegisteredCommitment = ( body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/from-scheme-launch/commit`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

//api for get commitment details of registered user--scheme Emailer
export const apiGetRegisteredCommitmentDetails = (ioRid, token) => {
  return new Promise((resolve,reject) => {
    httpGet({path: `/webapp/from-scheme-launch/commitment/${ioRid}`, queryParams:{token}})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

//api for get transaction details of registered user--scheme Emailer
export const apiGetTransactionStatusSchemeLaunch = (ioRid, token) => {
  return new Promise((resolve,reject) => {
    httpGet({path: `/webapp/from-scheme-launch/transaction/commitment/${ioRid}/pay`, queryParams:{token}})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

//api for get commitment details of registered user--scheme Emailer
export const apiGetRegisteredTransactionDetails = (orderId, token) => {
  return new Promise((resolve,reject) => {
    httpGet({path: `/webapp/from-scheme-launch/transaction/${orderId}`, queryParams:{token}})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

//api for save consent for registered user-- scheme Emailer

export const apiSaveSchemeEmailerConsent = ( body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/from-scheme-launch/commitment/consent`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

//api for get convenience fee -- scheme Emailer
export const apiGetSchemeEmailerConvenienceFee = () => {
  return new Promise((resolve,reject) => {
    httpGet({path: '/webapp/from-scheme-launch/transaction/convenience-fee'})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

//api for transaction commitment -- scheme Emailer

export const apiCommitmentTransactionSchemeEmailer = ( ioRid,reqParams, body) => {
  return new Promise((resolve,reject) => {
    httpPost({path: `/webapp/from-scheme-launch/transaction/commitment/${ioRid}/pay`, queryParams: reqParams, body:body})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

// api for get wallet details

export const getWalletSchemeEmailer = (token) => {
  return new Promise((resolve, reject) => {
    httpGet({
      path: "/webapp/from-scheme-launch/wallet",
      queryParams:token,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

//api for delete commitment by investor

export const apiDeleteInvestorCommitment = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({path: '/webapp/commitment', body: body })
    .then((res) => {
      if (res.status === STATUS_SUCCESS) {
        return resolve(res.description)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

//api for get confirmation for edit 
export const getEditDetails = (reqParam) => {
  return new Promise((resolve, reject) => {
    httpGet({
      path: "/webapp/edit-commitment",
      queryParams:reqParam,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
}

//api for modify commitment by investor

export const apiUpdateInvestorCommitment = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({
      path: `/webapp/commit`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}



export const apiEnableResendOtp = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({path: '/resend-otp', body: body })
    .then((res) => {
      if (res.status) {
        return resolve(res.description)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const apiSaveBankInfo = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/save-bank-info`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

export const apiDematInfo = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/save-demat-info`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

export const apiSaveBasicDetail = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/save-basic-detail`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

export const uploadFile = (file) => {
  return new Promise((resolve, reject) => {
    const form = new FormData();
    form.append('file', file)

    httpFileUpload({path: '/upload-document', file: form })
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })

}

export const apiEmailLoginInit = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/email-login-init`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

export const apiEmailLoginGetOtp = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/email-login-get-otp`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

export const apiSwitchAccount = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/switch-account`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}


export const apiNotifyAdmin = (body) => {
  return new Promise((resolve, reject) => {
    httpPost({
      path: `/webapp/notify-admin`,
      body: body
    })
      .then((res) => {
        return res.status ? resolve(res.data) : reject(res.description)
      })
      .catch((err) => {
        reject(err)
      });
  });
}

export const getNominationEsignURL = (reqParams) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    httpGet({ path: "/webapp/nomination/sign", JWT: JWT, queryParams: reqParams, })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const getNominationDrawDownDetails = (reqParams) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    const params = reqParams;

    httpGet({
      path: "/webapp/nomination/status",
      JWT: JWT,
      queryParams: params,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const apigetMcaList = (reqParams) => {
  return new Promise((resolve,reject) => {
    httpGet({path: '/webapp/mca-list', param: reqParams})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const apigetNominationList = (reqParams) => {
  return new Promise((resolve,reject) => {
    httpGet({path: '/webapp/nomination/list', param: reqParams})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const getFatcaDrawDownDetails = (reqParams) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    const params = reqParams;

    httpGet({
      path: "/webapp/fatca/status",
      JWT: JWT,
      queryParams: params,
    })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const apigetFatcaList = (reqParams) => {
  return new Promise((resolve,reject) => {
    httpGet({path: '/webapp/fatca/list', param: reqParams})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}
export const getFatcaEsignURL = (reqParams) => {
  return new Promise((resolve, reject) => {
    const JWT = localStorage.getItem("JWT");
    httpGet({ path: "/webapp/fatca/sign", JWT: JWT, queryParams: reqParams, })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};

export const apiGetCommitmentDetails = (ioRid) => {
  return new Promise((resolve,reject) => {
    httpGet({path: `/webapp/commitment/${ioRid}`})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const apiCommitmentTransaction = (ioRid, reqParams) => {
  return new Promise((resolve,reject) => {
    httpPost({path: `/webapp/transaction/commitment/${ioRid}/pay`, queryParams:reqParams})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const apiGetTransactionStatus = (OrderId) => {
  return new Promise((resolve,reject) => {
    httpGet({path: `/webapp/transaction/${OrderId}`})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const apiGetConvenienceFee = () => {
  return new Promise((resolve,reject) => {
    httpGet({path: '/webapp/transaction/convenience-fee'})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const apiGetCommitments = () => {
  return new Promise((resolve,reject) => {
    httpGet({path: '/webapp/commitments'})
    .then((res) => {
      if (res.status && res.data) {
        return resolve(res.data)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

export const apiUpdateInvName = (body) => {
  return new Promise((resolve, reject) => {
    httpPut({path: '/webapp/update-inv-name', body: body })
    .then((res) => {
      if (res.status === STATUS_SUCCESS) {
        return resolve(res.description)
      }
      return reject(res.description)
    })
    .catch((e) => {
      return reject(e)
    })
  })
}

// export const apiGetLogo = (partnerId) => {
//   console.log('image path called')
//   return new Promise((resolve, reject) => {
//     httpGet({ path: `/logo/${partnerId}`, responseType: 'blob' })
//     console.log('image path called 2nd')
//       .then((res) => {
//         console.log('image path called 3rd')
//         if (res.status && res.data) {
//           console.log('image path called 4rd')
//           const imageUrl = URL.createObjectURL(res.data);
//           console.log('image path', imageUrl)
//           return resolve(imageUrl); 
//         }
//         return reject(res.description);
//       })
//       .catch((e) => {
//         console.log('image path error', e)
//         return reject(e);
//       });
//   });
// };

export const apiGetLogo = (partnerId) => {
  return new Promise((resolve, reject) => {
    httpGetBlob({ path: `/logo/${partnerId}` })  
      .then((res) => {
        if (res.status && res.data) { 
          const imageUrl = URL.createObjectURL(res.data); 
          console.log('imgae path',imageUrl)
          resolve(imageUrl); 
        } else {
          reject("Error: Logo not found or failed to fetch.");
        }
      })
      .catch((e) => {
        reject(`Error fetching logo: ${e}`);
      });
  });
};

export const apiGetPartnerId = (path) => {
  return new Promise((resolve, reject) => {
    fetch(path)
      .then((response) => {
        if (response.ok) {
          const partnerId = response.headers.get('partner-id') || '0'; 
          console.log('partnerId:', partnerId);

          return resolve({ partnerId }); 
        } else {
          return reject("Error: Partner-Id not found or failed to fetch.");
        }
      })
      .catch((e) => {
        reject(e)
      });
  });
};



