import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';

function CardLayout({ children }) {
  const history = useHistory();
  const [logo, setLogo] = useState(null);

  const fetchLogo = () => {
    const partnerId = localStorage.getItem('partnerId'); 
  
      return `${process.env.REACT_APP_BASE_URL}/logo/${partnerId}`;  
   
    }

  useEffect(() => {
    setLogo(fetchLogo()); 
  }, []);

  return (
    <div className="art-board py-md-4 d-flex flex-column h-100">
      <div className="container card-flat flex-grow-1 p-4 p-md-4 overflow-auto">
        <div className="d-flex gap-3 align-items-center">
          <IconButton size="large" onClick={() => history.replace("/user/home")}>
            <ArrowBackIcon />
          </IconButton>
          <img
                src={logo}  
                alt={"logo"}
                className="p-4"
                width="180"
                onClick={() => history.push("/user/home")}
              />
        </div>

        {children}
      </div>
    </div>
  );
}

export default CardLayout;
